<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="About Us" />
  <!-- Breadcroumbs end -->

  <!-- Company Overview start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="inner-content">
            <img src="../assets/images/about.jpg" class="rounded float-lg-left mb40" alt="" />
            <h4 class="mt0">Company Overview</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s, when an unknown
              printer took a galley of type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged.
            </p>
            <h4>Our Mission</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s, when an unknown
              printer took a galley of type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged.
            </p>
            <h4>Our Vision</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s, when an unknown
              printer took a galley of type and scrambled it to make a type specimen book. It has
              survived not only five centuries
            </p>
            <div class="products-specification">
              <h3>Our products Specification</h3>
              <ul>
                <li>
                  <h5>Best quality product</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book.
                  </p>
                </li>
                <li>
                  <h5>One month free service</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries.
                  </p>
                </li>
                <li>
                  <h5>100% Guarantee</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book.
                  </p>
                </li>
                <li>
                  <h5>Free home delivery</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries.
                  </p>
                </li>
              </ul>
            </div>
            <div class="about-bee-sec">
              <h3>About Bees</h3>
              <div class="about-bee-holder">
                <div class="bee-icon">
                  <img src="../assets/images/queen-bee.png" alt="Queen Bee" />
                </div>
                <div class="about-bee-details">
                  <h5>Queen Bee</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries.
                  </p>
                </div>
              </div>
              <div class="about-bee-holder">
                <div class="bee-icon">
                  <img src="../assets/images/drone-bee.png" alt="Drone Bee" />
                </div>
                <div class="about-bee-details">
                  <h5>Drone Bee</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries.
                  </p>
                </div>
              </div>
              <div class="about-bee-holder">
                <div class="bee-icon">
                  <img src="../assets/images/worker-bee.png" alt="Worker Bee" />
                </div>
                <div class="about-bee-details">
                  <h5>Worker Bee</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Company Overview End -->

  <!-- Contact Button start -->
  <div class="bee-content-block order-request-btn-sec">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-9">
          <h4>The Best quality product, for <span>Beekeeping</span> with <span>Bee!</span></h4>
        </div>
        <div class="col-12 col-lg-3 text-right">
          <a href="" title="" class="bee-button hover-red">Request For Order</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact Button End -->
  <Testimonial />

  <Newsletter />
</template>

<script>
// @ is an alias to /src
// import BeeSlider from '@/components/Slider.vue';

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";
import Testimonial from "@/components/Testimonial.vue";

export default {
  name: "About",
  components: {
    Breadcrumbs,
    Newsletter,
    Testimonial,
  },
};
</script>
