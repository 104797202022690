<template>
  <div class="bee-content-block testimonial-sec text-center">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title-bg">Testimonial</h2>
        </div>
      </div>
      <div class="testimonial">
        <div class="testimonial-item">
          <img src="../assets/images/testimonial.jpg" alt="Client 1" />
          <h4>Client Name</h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.<i class="fa fa-quote-right"></i>
          </p>
        </div>
        <div class="testimonial-item">
          <img src="../assets/images/testimonial.jpg" alt="Client 2" />
          <h4>Client Name</h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.<i class="fa fa-quote-right"></i>
          </p>
        </div>
        <div class="testimonial-item">
          <img src="../assets/images/testimonial.jpg" alt="Client 3" />
          <h4>Client Name</h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.<i class="fa fa-quote-right"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Testimonial",
  mounted() {
    // eslint-disable-next-line no-undef
    $(".testimonial").owlCarousel({
      items: 1,
      nav: false,
      dots: true,
      autoplay: true,
      loop: true,
      mouseDrag: true,
      touchDrag: true,
    });
  },
};
</script>

<style></style>
